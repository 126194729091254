import './Tokyopict.css';


function Tokyopict(props){
    const {Tokyo , onBgClick } = props;

    
    return(
        <div className="Tokyopict" >
            <div className="Tokyobg"  onClick={onBgClick} />
            <div className="Tokyocontent">
                    <img src={Tokyo.pictureurl} />
                    <p>{Tokyo.title}</p>
            </div>
        </div>

    );
}
export default Tokyopict;