import './Popup.css';
import {useEffect} from 'react';

function Popup(props){

useEffect(() =>{

console.log('Popup Start');
document.documentElement.style.overflow = 'hidden';

return ()=>{

    console.log('Popup End');
    document.documentElement.style.overflow = 'auto';
}

} ,[]);

return <div className='popup' onClick={props.onPopupClose} />;

}

export default Popup;