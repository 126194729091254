import './App.css';
import Appheader from './components/appheader';
import Tokyo from './components/Tokyo';
import Tokyopict from './components//Tokyopict';
import AppSearch from './components//AppSearch';
import Tokyos from './data/Tokyoelements';
import Popup from './Popup';
import { useState } from 'react';
import { useEffect } from 'react';

const emptyNote = {
    content: '',
    author: '',
  };
  

function App() {
 
    //factor for search picture
    const [SelectedTokyo , setSelectedTokyo] = useState(null);
    const [SearchText , SetText] = useState('');

    //factor for comments 
    const [note, setNote] = useState(emptyNote);
    const [editNote, setEditNote] = useState(null);
    const [allNotes, setAllNotes] = useState([]);

    // set comment note function
    function onNoteValueChange(event) {
        const { name, value } = event.target;
        setNote(prevNote => {
        return {
            ...prevNote,
            [name]: value,
        };
        });
    }

    // set edit comment note function
    function onEditNoteValueChange(event) {
        const { name, value } = event.target;
        setEditNote(prevEditNote => {
          return {
            ...prevEditNote,
            [name]: value,
          };
        });
      }

    function onNoteSubmit(event) {
        event.preventDefault();

        // Add note
        setAllNotes(prevAllNotes => {
        const newNote = { ...note };
        newNote.id = Date.now().toString();
        return [newNote, ...prevAllNotes];
        });

        // Clear form
        setNote(emptyNote);
    }

    //edit comment function
    function onEditNoteSubmit(event) {
        event.preventDefault();
    
        // Edit note
        setAllNotes(prevAllNotes => {
          return prevAllNotes.map(note => {
            if (note.id !== editNote.id) return note;
            return editNote;
          });
        });
        setEditNote(null);
      }

    //delete comment box function
    function onNoteDelete(noteID){
        setAllNotes((prevallnote)=>{

            return prevallnote.filter(theNote=>theNote.id !== noteID);
        });

    }

    // create comment box
    const allNotesElements = allNotes.map(theNote => {
        return (
          <div key={theNote.id} className="app-note">
            <p>{theNote.content}</p>
            <h5>{theNote.author}</h5>
            <p>
              <a
                onClick={() => {
                  setEditNote(theNote);
                }}
              >
                Edit
              </a>
              <span> | </span>
              <a
                onClick={() => {
                  onNoteDelete(theNote.id);
                }}
              >
                Delete
              </a>
            </p>
          </div>
        );
      });
    
    // create comment edit box
    let editNoteElement = null;
    if (!!editNote) {
    editNoteElement = (
        <div className="app-edit-note">
        <form onSubmit={onEditNoteSubmit}>
            <p>
            <textarea
                rows="3"
                placeholder="Content"
                name="content"
                value={editNote.content}
                onChange={onEditNoteValueChange}
            />
            </p>
            <p>
            <input
                type="text"
                placeholder="type your name"
                name="author"
                value={editNote.author}
                onChange={onEditNoteValueChange}
            />
            </p>
            <p>
            <button type="submit">Edit</button>
            </p>
        </form>
        </div>
    );
    }
        
    
    //search and show picture function
    function OnTokyoopen(TheTokyo) {
        setSelectedTokyo(TheTokyo)
        
    }
    //close picture popup
    function OnTokyoclose() {
        setSelectedTokyo(null);
        
    }

    const Tokyoelements = Tokyos.filter((Tokyo)=>{
        return Tokyo.title.includes(SearchText);
    }).map((Tokyoi,index)=> {
        return <Tokyo key={index} Tokyotou={Tokyoi} OnTokyoopen={OnTokyoopen} />
    });
    let tokyopost = null;
    if(!!SelectedTokyo ){
        tokyopost = <Tokyopict id="test" Tokyo={SelectedTokyo} onBgClick={OnTokyoclose}  />

    }


    //HTML and main frontend
    return (
        <div className="app">
            <Appheader />
            
            <section className='App-Section'>
            <div className='App-Container'>
            



        
            <AppSearch value={SearchText} OnValueChange={SetText} />
            <div className='grid_pattern'>
                {Tokyoelements}
            </div>
            
            
            {tokyopost}

            <form onSubmit={onNoteSubmit} >
          <p>
            <textarea
              rows="3"
              placeholder="Please write the content"
              name="content"
              value={note.content}
              onChange={onNoteValueChange}
              className='forminput'
            />
          </p>
          <p>
            <input type="text" className='forminput' placeholder="Please type your name" name="author" value={note.author} onChange={onNoteValueChange} />
          </p>
          <p>
            <button type="submit">Submit</button>
          </p>
        </form>

        </div>

        <div className="app-notes">{allNotesElements}</div>

            {editNoteElement}
            </section>
            
        </div>
    );
}

export default App;
